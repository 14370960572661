<script setup>
import { onMounted, onUpdated, ref } from 'vue';

const currentTab = ref('webinars');

const showWebinars = () => {
    currentTab.value = 'webinars';
};

const showParticipants = () => {
    currentTab.value = 'participants';
};


const souscribers = ref([]);
async function getAllSouscribers() {
    // fetch(
    //     `http://localhost/highfive_site/backend/route.php?goto=getAllwebinaires`,

    fetch(
        `/backend/route.php?goto=webinaires`,
        {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        }
    )
        .then((response) => response.json())
        .then((response) => {

            souscribers.value = response;
            // console.log("souscribers", souscribers.value);
        });
}

const reunion_infos = ref([])
async function getAllWebinaire() {
    //  fetch(
    //      `http://localhost/highfive_site/backend/route.php?goto=getAllwebinaires&target=getAllWebinaire`,

    fetch(
        `/backend/route.php?goto=webinaires&target=getAllWebinaire`,
        {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        }
    )
        .then((response) => response.json())
        .then((response) => {

            reunion_infos.value = response;
        });
}


onUpdated(() => { });
onMounted(() => {
    getAllWebinaire();
    getAllSouscribers();
});



const formatDate = (date) => {
    const inputDate = new Date(date);
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    const formattedDate = inputDate.toLocaleDateString("fr-FR", options);
    return formattedDate;
};

const formatTime = (time) => {
    const inputDateTime = new Date(time);  // Utilisez le paramètre 'time' ici
    const options = { hour: "numeric", minute: "numeric", hour12: false };

    const formattedTime = inputDateTime.toLocaleTimeString("fr-FR", options);

    // Extracting hours and minutes
    const [hours, minutes] = formattedTime.split(":");

    const finalFormattedTime = `${hours}h${minutes}`;
    return finalFormattedTime;
};


</script>

<template>
    <!-- component -->
    <div class="w-full h-screen overflow-scroll bg-gray-200 sm:px-6">
        <!-- component -->
        <div class="sm:flex sm:items-center sm:justify-between">
            <div>
                <div class="flex items-center gap-x-3">
                    <h2 class="text-3xl font-medium text-gray-700">Webinaires</h2>

                    <span
                        class="px-3 py-1 text-xs rounded-full text-emerald-500 bg-emerald-100 dark:bg-gray-800 dark:text-blue-400">Disponibles</span>
                </div>


            </div>

            <div class="flex items-center mt-4 gap-x-3">
                <button
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3098_154395)">
                            <path
                                d="M13.3333 13.3332L9.99997 9.9999M9.99997 9.9999L6.66663 13.3332M9.99997 9.9999V17.4999M16.9916 15.3249C17.8044 14.8818 18.4465 14.1806 18.8165 13.3321C19.1866 12.4835 19.2635 11.5359 19.0351 10.6388C18.8068 9.7417 18.2862 8.94616 17.5555 8.37778C16.8248 7.80939 15.9257 7.50052 15 7.4999H13.95C13.6977 6.52427 13.2276 5.61852 12.5749 4.85073C11.9222 4.08295 11.104 3.47311 10.1817 3.06708C9.25943 2.66104 8.25709 2.46937 7.25006 2.50647C6.24304 2.54358 5.25752 2.80849 4.36761 3.28129C3.47771 3.7541 2.70656 4.42249 2.11215 5.23622C1.51774 6.04996 1.11554 6.98785 0.935783 7.9794C0.756025 8.97095 0.803388 9.99035 1.07431 10.961C1.34523 11.9316 1.83267 12.8281 2.49997 13.5832"
                                stroke="currentColor" stroke-width="1.67" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3098_154395">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span>Import</span>
                </button>

                <button
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <span>Créer un webinaire</span>
                </button>
            </div>
        </div>

        <div class="mt-6 md:flex md:items-center md:justify-between">
            <!-- Boutons pour basculer entre webinaires et participants -->
            <div class="flex items-center mt-4 gap-x-3">
                <button @click="showWebinars"
                    :class="{ 'bg-blue-500': currentTab === 'webinars', 'text-white': currentTab === 'webinars' }"
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm border-radius">
                    <span>Webinaires</span>
                </button>
                <button @click="showParticipants"
                    :class="{ 'bg-blue-500': currentTab === 'participants', 'text-white': currentTab === 'participants' }"
                    class="flex items-center justify-center w-1/2 px-5 py-2 text-sm border-radius">
                    <span>Participants</span>
                </button>
            </div>


            <div class="relative flex items-center mt-4 md:mt-0">
                <span class="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </span>

                <input type="text" placeholder="Rechercher"
                    class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40">
            </div>
        </div>

        <!-- Liste des webinaires -->
        <div v-if="currentTab === 'webinars'" class="mt-6 ">
            <div class="h-[700px] overflow-y-scroll">

                <div class="grid grid-cols-1 gap-10 bg-white md:grid-cols-2 lg:grid-cols-3">
                    <div class="flex flex-col justify-between overflow-hidden text-center rounded shadow-lg "
                        v-for="reunion_info in reunion_infos" :key="reunion_info.id">
                        <img class="object-cover w-full h-60"
                            :src="require(`../assets/images/` + reunion_info.img)"
                            alt="Sunset in the mountains" />

                        <div class="py-4">
                            <p class="text-lg font-bold text-gray-700 ">
                                {{ formatDate(reunion_info.date_reunion) }}
                                à {{ formatTime(reunion_info.date_reunion) }}
                            </p>

                        </div>
                        <div class="">
                            <span
                                class="inline-block px-4 py-2 mb-5 mr-2 text-sm font-semibold text-white rounded-lg bg-amber-500 hover:cursor-pointer">Editer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Affichage de la liste des webinaires. -->
        <div v-if="currentTab === 'participants'" class="flex flex-col mt-6 ">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div
                        class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg h-[700px] overflow-y-scroll">
                        <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead class="sticky top-0 bg-gray-50 dark:bg-gray-800">
                                <tr>
                                    <th scope="col"
                                        class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Nom
                                    </th>

                                    <th scope="col"
                                        class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Prénoms
                                    </th>

                                    <th scope="col"
                                        class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Téléphone
                                    </th>

                                    <th scope="col"
                                        class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        Email</th>

                                    <th scope="col"
                                        class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                        Webinaires</th>

                                    <th scope="col"
                                        class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                        Actions</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                <tr v-for="souscriber, index in souscribers" :key="index">
                                    <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <h2 class="font-medium text-gray-800 dark:text-white ">{{
                        souscriber.lastname }}</h2>

                                        </div>
                                    </td>
                                    <td class="px-4 py-4 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 class="text-gray-700 dark:text-gray-200">{{ souscriber.firstname }}</h4>

                                        </div>
                                    </td>
                                    <td class="px-4 py-4 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 class="text-gray-700 dark:text-gray-200">{{ souscriber.phone }}</h4>

                                        </div>
                                    </td>

                                    <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                        <div
                                            class="inline px-3 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                            {{ souscriber.email }}
                                        </div>
                                    </td>

                                    <td class="px-4 py-4 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 class="text-gray-700 truncate dark:text-gray-200"> {{
                                                souscriber.sessions_reunion }}</h4>

                                        </div>
                                    </td>

                                    <td class="px-4 py-4 text-sm whitespace-nowrap">
                                        <button
                                            class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:bg-gray-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<style scoped>
/* Ajoutez vos styles Tailwind spécifiques à ce composant ici */
</style>