import { createRouter, createWebHistory } from 'vue-router';
import Accueil from './views/AccueilViews.vue';
import Formation from './views/FormationViews.vue';
import Engagements from './views/EngagementsViews.vue';
import JoinUs from './views/JoinUsViews.vue';
import Entreprise from './views/EntrepriseViews.vue';
import Faq from './views/FaqViews.vue';
import DefaultComponent from "@/components/partials/DefaultComponent.vue";
import Contact from './views/ContactViews.vue';
import Candidature from './views/CandidatureViews.vue';
import ContactEntreprises from './views/ContactEntreprisesViews.vue';
import NosReunionsViews from './views/NosReunionsViews.vue';
import AdminComponent from "@/components/partials/AdminComponent.vue";
import Connexion  from "@/components/forms/ConnexionComponent.vue";
import Inscription from "@/components/forms/InscriptionComponent.vue";
import Dashboard from './views/DashboardViews.vue';
import AdminWebinaires from './views/AdminWebinairesViews.vue';
import Configuration from './views/ConfigurationViews.vue';
import MentionLegale from './views/MentionsLegalViews.vue';
import DonneePersonnelle from './views/DonneesPersonnelleViews.vue';


// // Fonction pour vérifier l'authentification de l'utilisateur
// const isAuthenticated = () => {
//   // Vérifiez ici si l'utilisateur est authentifié, par exemple, en vérifiant la présence d'un jeton dans le stockage local
//   return localStorage.getItem('authToken') !== null;
// };

// // Garde de navigation pour protéger les routes
// const authGuard = (to, from, next) => {
//   // Vérifiez si l'utilisateur est authentifié avant d'autoriser l'accès à la route
//   if (isAuthenticated()) {
//     // Utilisateur authentifié, autoriser l'accès
//     next();
//   } else {
//     // Utilisateur non authentifié, rediriger vers la page de connexion
//     next('/Connexion');
//   }
// };

const routes = [

  {
    path: '/',
    redirect : { name:'Accueil'},
    component: DefaultComponent,
    children:[
      {path:'/', name:'Accueil', component:Accueil },
      {path:"/Formation", name:"Formation", component:Formation},
      {path:"/Engagements",  name:"Engagements", component:Engagements},
      {path:"/JoinUs",  name:"JoinUs", component:JoinUs},
      {path:"/Entreprise",  name:"Entreprise", component:Entreprise},
      {path:"/Faq",  name:"Faq", component:Faq},
      {path:"/Contact",  name:"Contact", component:Contact},
      {path:"/Candidature",  name:"Candidature", component:Candidature},
      {path:"/ContactEntreprises",  name:"ContactEntreprises", component:ContactEntreprises},
      {path:"*", redirect : { name:'Accueil'}},
      {path:"/Reunions", name:"Reunions", component:NosReunionsViews},
      {path:"/Connexion",  name:"Connexion", component:Connexion},
      {path:"/MentionLegale",  name:"MentionLegale", component:MentionLegale},
      {path:"/DonneePersonnelle",  name:"DonneePersonnelle", component:DonneePersonnelle},

    ]
  },
  
    
  {
    path: '/',
    redirect : { name:'Accueil'},
    component: AdminComponent,
    children:[
      
      // { path: '/Dashboard', name: 'Dashboard', component: Dashboard, beforeEnter: authGuard },
      // { path: '/Inscription', name: 'Inscription', component: Inscription },
      // { path: '/Configuration', name: 'Configuration', component: Configuration, beforeEnter: authGuard },
      // { path: '/AdminWebinaires', name: 'AdminWebinaires', component: AdminWebinaires, beforeEnter: authGuard },
      // {path:"*", redirect : { name:'Accueil'}}

      { path: '/Dashboard', name: 'Dashboard', component: Dashboard},
      { path: '/Inscription', name: 'Inscription', component: Inscription},
      { path: '/Configuration', name: 'Configuration', component: Configuration},
      { path: '/AdminWebinaires', name: 'AdminWebinaires', component: AdminWebinaires},
      {path:"*", redirect : { name:'Accueil'}}
    
      
    ]
  },
];


const router = createRouter({
  history: createWebHistory(),
  mode:'history',
  routes,
  
});

export default router;