<script setup>
import { ref, onBeforeMount } from 'vue';
// import { useRoute } from 'vue-router';

// const router = useRoute();

const candidatures = ref([]);
const totalCandidatures = ref(0);

const getData = async () => {
    try {
        // const response = await fetch(`http://localhost/highfive_site/backend/route.php?goto=candidate`);
        const response = await fetch(`/backend/route.php?goto=candidate`);
        // const response = await fetch(`https://highfiveuniversity.bj//backend/route.php?goto=candidate`);
        const jsonData = await response.json();

        candidatures.value = jsonData;
        totalCandidatures.value = jsonData.length;

    } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
    }
};

onBeforeMount(() => {
    getData();
});



const isValidDateFormat = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
};

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
};

const startDate = ref('');
const endDate = ref('');
const nbr_after_check = ref(0);

const submitForm = async () => {
    try {
        // const response = await fetch('http://localhost/highfive_site/backend/route.php?goto=candidate&target=candidate_periode',
        const response = await fetch('/backend/route.php?goto=candidate&target=candidate_periode',
         {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                start: startDate.value,
                end: endDate.value
            })
        });

        if (response.ok) {
            const data = await response.json(); // Convertir la réponse en JSON
            candidatures.value = data;
            nbr_after_check.value = data.length;
        } else {
            console.error('Erreur lors de la requête:', response.statusText);
        }
    } catch (error) {
        console.error('Erreur lors de la requête:', error);
    }
};

const candidate = ref({});
const showDetail = async (id) => {
    try {
    //     const response = await fetch('http://localhost/highfive_site/backend/route.php?goto=candidate&target=one_candidate', 
        const response = await fetch('/backend/route.php?goto=candidate&target=one_candidate', 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
            })
        });

        if (response.ok) {
            const data = await response.json(); // Convertir la réponse en JSON
            candidate.value = data;

            showModal.value = true;
        } else {
            console.error('Erreur lors de la requête:', response.statusText);
        }
    } catch (error) {
        console.error('Erreur lors de la requête:', error);
    }

}

const showModal = ref(false); // Variable pour contrôler l'affichage du modal

const closeModal = () => {
    showModal.value = false; // Fermer le modal lorsqu'on clique sur le bouton de fermeture
};


</script>

<template>
    <!-- Main content -->
    <main class="flex-1 h-screen overflow-x-hidden overflow-y-auto bg-gray-200 ">
        <div class="px-6 py-8 mx-auto">
            <h3 class="text-3xl font-medium text-gray-700">Tableau de bord</h3>


            <div class="mt-4">
                <div class="flex flex-wrap -mx-6">
                    <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
                        <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                            <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full">
                                <svg class="w-8 h-8 text-white" viewBox="0 0 28 30" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>

                            <div class="mx-5">
                                <h4 class="text-2xl font-semibold text-gray-700">{{ totalCandidatures }}</h4>
                                <div class="text-gray-500">Candidatures au Total</div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-wrap items-center w-full gap-4 px-5 py-4 bg-white rounded-md shadow-sm sm:w-1/2 xl:w-1/3">

                        <form class="flex flex-wrap gap-4">
                            <div class="flex items-center justify-center gap-1">
                                <label for="start_date" class="text-gray-700">Début : </label>
                                <input type="date" id="start_date" v-model="startDate" required
                                    class="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
                            </div>

                            <div class="flex items-center justify-center gap-1">
                                <label for="end_date" class="text-gray-700">Fin:</label>
                                <input type="date" id="end_date" v-model="endDate" required
                                    class="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">

                            </div>

                        </form>
                        <button @click="submitForm"
                            class="px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-600">Rechercher</button>
                    </div>

                    <div class="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
                        <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                            <div class="p-3 bg-pink-600 bg-opacity-75 rounded-full">

                                <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor"
                                    class="w-8 h-8 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z">
                                    </path>
                                </svg>
                            </div>

                            <div class="mx-5">
                                <h4 class="text-2xl font-semibold text-gray-700">{{ nbr_after_check }}</h4>
                                <div class="text-gray-500">Candidature(s) sur la periode selectionnées</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="flex flex-col mt-8">
                    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            class="inline-block max-h-[700px] min-w-full overflow-h-scroll align-middle border-b border-gray-200 shadow sm:rounded-lg">
                            <table class="min-w-full ">
                                <thead class="sticky bg-gray-700 -top-2">
                                    <tr>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Nom</th>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Prénom(s)</th>

                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Adresse Email</th>

                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Date de naissance</th>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Adresse / Ville</th>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Téléphone 1</th>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Téléphone 2</th>
                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Date d'inscription</th>

                                        <th
                                            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                                            Actions</th>
                                    </tr>
                                </thead>

                                <tbody class="bg-white">
                                    <tr v-for="data, index in candidatures" :key="index">
                                        <td class="px-6 py-4 font-semibold whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">{{ data.last_name }}</div>

                                        </td>

                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">{{ data.first_name }}</div>

                                        </td>

                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <span
                                                class="inline-flex px-2 font-semibold leading-5 text-green-800 bg-green-100 rounded-full">{{
                                                    data.email }}</span>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">
                                                {{ isValidDateFormat(data.birth) ? formatDate(data.birth) : data.birth
                                                }}
                                            </div>


                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">{{ data.address }}</div>

                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">{{
                                                data.phone ? data.phone : "---" }}</div>

                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">
                                                {{ data.phone2 ? data.phone2 : "---" }}</div>

                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div class="text-sm leading-5 text-gray-900">{{ formatDate(data.created_at) }}
                                            </div>

                                        </td>

                                        <td
                                            class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap border-b border-gray-200">
                                            <div @click="showDetail(data.id)"
                                                class="text-indigo-600 cursor-pointer hover:text-indigo-900">Détails
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showModal" class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                aria-modal="true">
                <div class="flex items-center justify-center min-h-screen">
                    <div class="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75" aria-hidden="true"></div>
                    <div class="relative bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-[1200px] max-h-[900px]"
                        role="document">
                        <div class="absolute top-0 right-0 pt-4 pr-4">
                            <button @click="closeModal" type="button"
                                class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500">
                                <span class="sr-only">Fermer</span>
                                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="px-4 py-8 sm:p-10">
                            <!-- Contenu du modal avec les données du candidat -->
                            <h3 class="text-lg font-medium text-gray-900">Informations détaillées sur le candidat</h3>
                            <p class="mt-2 text-sm text-gray-500"><strong>Date de la candidature :</strong> {{
                                formatDate(candidate.created_at) }}</p>
                            <!-- Affichage de toutes les données du candidat -->
                            <div class="flex flex-col mt-4 space-y-4">
                                <div class="grid grid-cols-3 p-4 shadow-md">
                                    <div>
                                        <p><strong>Civilité:</strong> {{ candidate.civility }}</p>
                                        <p><strong>Prénom:</strong> {{ candidate.first_name }}</p>
                                        <p><strong>Nom:</strong> {{ candidate.last_name }}</p>
                                        <p><strong>Date de naissance:</strong> {{ isValidDateFormat(candidate.birth) ?
                                            formatDate(candidate.birth) : candidate.birth }}</p>
                                        <p><strong>Nationalité:</strong> {{ candidate.nationality }}</p>
                                    </div>
                                    <div>

                                        <p><strong>Document d'identité:</strong> {{ candidate.identity_document }}</p>
                                        <p><strong>Numéro de document:</strong> {{ candidate.document_number }}</p>
                                        <p><strong>Email:</strong> {{ candidate.email }}</p>
                                        <p><strong>Adresse:</strong> {{ candidate.address }}</p>
                                    </div>
                                    <div>
                                        <p><strong>Ville:</strong> {{ candidate.city }}</p>
                                        <p><strong>Quartier:</strong> {{ candidate.district }}</p>
                                        <p><strong>Téléphone:</strong> {{ candidate.phone }}</p>
                                        <p><strong>Téléphone secondaire:</strong> {{ candidate.phone2 ? candidate.phone2
                                            : '---' }}</p>
                                    </div>
                                </div>

                                <div class="grid grid-cols-3 p-4 shadow-md">
                                    <div>
                                        <p><strong>Niveau d'étude:</strong> {{ candidate.study_level }}</p>
                                        <p><strong>Année d'obtention:</strong> {{ candidate.year_graduated }}</p>

                                    </div>
                                    <div>
                                        <p><strong>Université:</strong> {{ candidate.university }}</p>
                                        <p><strong>Nom du diplôme obtenu:</strong> {{ candidate.graduated_name }}</p>
                                    </div>
                                    <div>
                                        <p><strong>Situation actuelle:</strong> {{ candidate.actual_situation }}</p>
                                        <p><strong>Niveau d'anglais:</strong> {{ candidate.english_level }}</p>
                                    </div>
                                </div>
                                <div class="p-4 shadow-md">
                                    <p><strong>Pourquoi nous:</strong> {{ candidate.why_us }}</p>
                                    <p><strong>Vos attentes:</strong> {{ candidate.your_expectations }}</p>
                                    <p><strong>Just imagine:</strong> {{ candidate.just_imagin }}</p>
                                    <p><strong>Votre futur:</strong> {{ candidate.your_futur }}</p>
                                    <p><strong>Comment nous avez-vous connus:</strong> {{ candidate.know_us }}</p>
                                    <p><strong>Acceptation des règles:</strong> {{ candidate.rules === 1 ? 'Oui' : 'Non'
                                    }}</p>
                                    <p><strong>Consentement:</strong> {{ candidate.consent === 1 ? 'Oui' : 'Non' }}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>



  
<style scoped>
/* Add scoped CSS styles here */
</style>
  