<template>
  <div class="flex items-center justify-center h-screen overflow-hidden bg-amber-500">
    <div class="w-10/12 bg-white lg:w-5/12 md:6/12 shadow-3xl">
      <div class="absolute p-4 transform -translate-x-1/2 -translate-y-1/2 bg-gray-800 rounded-full left-1/2 md:p-8">
        <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="white" width="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path>
</svg>
      </div>
      <form @submit.prevent="register" class="p-12 md:p-24">
        
        <div class="flex items-center mb-6 text-lg md:mb-8">
          <svg class="absolute ml-3" width="24" viewBox="0 0 24 24">
            <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/>
          </svg>
          <input type="email" v-model="email" class="w-full py-2 pl-12 bg-gray-200 md:py-4 focus:outline-none" placeholder="Email" />
        </div>
        <div class="flex items-center mb-6 text-lg md:mb-8">
          <svg class="absolute ml-3" viewBox="0 0 24 24" width="24">
            <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
          </svg>
          <input type="password" v-model="password" class="w-full py-2 pl-12 bg-gray-200 md:py-4 focus:outline-none" placeholder="Mot de passe" />
        </div>
        <div class="flex items-center mb-6 text-lg md:mb-8">
          <svg class="absolute ml-3" viewBox="0 0 24 24" width="24">
            <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"/>
          </svg>
          <input type="passwordConfirm" v-model="passwordConfirm" class="w-full py-2 pl-12 bg-gray-200 md:py-4 focus:outline-none" placeholder="Confirmez mot de passe" />
        </div>
        <button type="submit" class="w-full p-2 font-medium text-white uppercase bg-gradient-to-b from-gray-700 to-gray-900 md:p-4">Inscrire utilisateur</button>
      </form>
    </div>
  </div>
</template>

<script setup>
// import { ref } from 'vue';


// const email = ref('');
// const password = ref('');
// const confirmPassword = ref('');


const register = () => {
 
  // console.log('Email:', email.value);
  // console.log('Password:', password.value);
  // console.log('Username:', confirmPassword.value);
};
</script>
