<template>
    <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 h-screen">
        <div class="px-6 py-8 mx-auto">

            <h3 class="text-3xl font-medium text-gray-700">Configurations</h3>

            <div class="mt-4">
                <div class="flex flex-wrap -mx-6">
                    <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
                        <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                            <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full">

                                <svg class="w-8 h-8 text-white" data-slot="icon" fill="none" stroke-width="1.5"
                                    stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z">
                                    </path>
                                </svg>
                            </div>

                            <div class="mx-5">
                                <div class="text-gray-500">Date de début des inscriptions :</div>
                                <h4 class="text-2xl font-semibold text-gray-700">{{ debutInscription ? debutInscription :
                                    "Non definie" }}</h4>
                            </div>
                        </div>
                    </div>

                    <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
                        <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                            <div class="p-3 bg-indigo-600 bg-opacity-75 rounded-full">

                                <svg class="w-8 h-8 text-white" data-slot="icon" fill="none" stroke-width="1.5"
                                    stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z">
                                    </path>
                                </svg>
                            </div>

                            <div class="mx-5">
                                <div class="text-gray-500">Date de démarrage du concours :</div>
                                <h4 class="text-2xl font-semibold text-gray-700">{{ debutconcours ? debutconcours :
                                    "Non definie" }}</h4>
                            </div>
                        </div>
                    </div>

                    <div class="w-full px-6 mt-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
                        <div class="flex items-center px-5 py-6 bg-white rounded-md shadow-sm">
                            <div class="p-3 bg-pink-600 bg-opacity-75 rounded-full">

                                <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor"
                                    class="w-8 h-8 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z">
                                    </path>
                                </svg>
                            </div>

                            <div class="mx-5">
                                <div class="text-gray-500">Date de début des cours :</div>
                                <h4 class="text-2xl font-semibold text-gray-700">{{ debutCours ? debutCours : "Non definie"
                                }}</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="p-4 flex justify-end"> <span @click="showModal(id)"
                    class="  bg-slate-500 hover:bg-slate-700 p-2 rounded-md text-white cursor-pointer">Modifer dates</span>
            </div>
        </div>

        <!-- Modal backdrop -->
        <div v-if="showDateMode" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            aria-hidden="true">
            <!-- Modal content -->
            <div class="bg-white rounded-lg p-8 max-w-md w-full">
                <!-- Modal header -->
                <div class="flex items-center justify-between mb-4">
                    <h3 class="text-lg font-semibold">Éditer la date</h3>
                    <!-- Bouton pour fermer le modal -->
                    <button @click="closeModal" class="text-gray-500 hover:text-gray-700 focus:outline-none">
                        <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                        </svg>
                    </button>
                </div>
                <!-- Modal body - Formulaire -->
                <div>
                    <!-- Date de début des inscriptions -->
                    <div class="mb-4">
                        <label for="depot_dossier" class="block text-sm font-medium text-gray-700">Date de début des
                            inscriptions</label>
                        <input type="date" id="depot_dossier " v-model="inscription"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <!-- Date de démarrage du concours : -->
                    <div class="mb-4">
                        <label for="next_renter" class="block text-sm font-medium text-gray-700">Date de démarrage du
                            concours :</label>
                        <input type="date" id="next_renter" v-model="concours"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <!-- Date de début des cours : : -->
                    <div class="mb-4">
                        <label for="next_renter" class="block text-sm font-medium text-gray-700">Date de début des cours
                            :</label>
                        <input type="date" id="next_renter" v-model="cours"
                            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>


                </div>
                <!-- Boutons d'action -->
                <div class="flex justify-end mt-4">
                    <!-- Bouton Annuler -->
                    <span @click="closeModal"
                        class="bg-gray-200 text-gray-700 px-4 py-2 rounded-md mr-2 hover:bg-gray-300 focus:outline-none cursor-pointer">
                        Annuler
                    </span>
                    <!-- Bouton Enregistrer -->
                    <span @click="saveDate"
                        class="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none cursor-pointer">
                        Enregistrer
                    </span>
                </div>
            </div>
        </div>
    </main>
</template>


<script setup>
import { onMounted, ref } from 'vue';

const debutInscription = ref('');
const debutconcours = ref('...');
const debutCours = ref('...');

const inscription = ref(null);
const concours = ref(null);
const cours = ref(null);
const id = ref(null);

const showDateMode = ref(false);

const getDate = () => {
    // fetch(`http://localhost/highfive_site/backend/route.php?goto=infos`)
    fetch(`/backend/route.php?goto=infos`)
        .then(response => response.json())
        .then(response => {
            // console.log(response);
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            id.value = response.id;
            inscription.value = response.depot_dossier;
            concours.value = response.next_concours;
            cours.value = response.next_renter;
            debutInscription.value = response.depot_dossier ? new Date(response.depot_dossier).toLocaleDateString('fr-FR', options) : null;
            debutconcours.value = response.next_concours ? new Date(response.next_concours).toLocaleDateString('fr-FR', options) : null;
            debutCours.value = response.next_renter ? new Date(response.next_renter).toLocaleDateString('fr-FR', options) : null;
        })
        .catch(error => {
            console.error('Erreur lors de la récupération des données :', error);
        });
};

onMounted(() => {
    getDate();
});

const saveDate = async () => {
    try {
        // Créer un objet contenant les données à envoyer au serveur
        const dataToSend = {
            debutInscription: inscription.value,
            debutconcours: concours.value,
            debutCours: cours.value,
            id: id.value
        };
        // console.log(dataToSend);

        // Effectuer une requête POST vers l'URL spécifiée avec les données JSON
        // const response = await fetch('http://localhost/highfive_site/backend/route.php?goto=infos&target=infos_update', {
        const response = await fetch('/backend/route.php?goto=infos&target=infos_update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: dataToSend // Envoyer les données JSON
        });

        // console.log(response);
        // Vérifier si la réponse est ok (status 200-299)
        if (response.ok) {
            // Convertir la réponse en JSON
            const responseData = await response.json();

            // Options pour le format de date en français
            const options = { day: 'numeric', month: 'long', year: 'numeric' };

            // Mettre à jour les valeurs des dates dans la vue avec les nouvelles données reçues
            debutInscription.value = responseData.depot_dossier ? new Date(responseData.depot_dossier).toLocaleDateString('fr-FR', options) : null;
            debutconcours.value = responseData.next_concours ? new Date(responseData.next_concours).toLocaleDateString('fr-FR', options) : null;
            debutCours.value = responseData.next_rentrer ? new Date(responseData.next_rentrer).toLocaleDateString('fr-FR', options) : null;
        } else {
            // Gérer les erreurs de réponse HTTP
            console.error('Erreur lors de la requête:', response.statusText);
        }
    } catch (error) {
        // Gérer les erreurs de requête
        console.error('Erreur lors de la requête:', error);
    }

    // Fermer le formulaire modal quelle que soit la réponse de la requête
    closeModal();
};

const closeModal = () => {
    showDateMode.value = false;
    inscription.value = null;
    concours.value = null;
    cours.value = null;

};

const showModal = () => {
    showDateMode.value = true;
};

</script>

  
<style scoped>
/* Add scoped CSS styles here */
</style>
  